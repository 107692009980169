import {SectionNotification} from 'wix-ui-tpa';
import Error from 'wix-ui-icons-common/on-stage/Error';
import React from 'react';
import s from './DigitalLinksError.scss';
import {useTranslation} from 'yoshi-flow-editor-runtime';

export enum DigitalLinksErrorDataHook {
  root = 'DigitalLinksErrorDataHook.root',
  errorTitle = 'DigitalLinksErrorDataHook.errorTitle',
  errorDescription = 'DigitalLinksErrorDataHook.errorDescription',
}

export function DigitalLinksError() {
  const {t} = useTranslation();

  return (
    <SectionNotification type="error" data-hook={DigitalLinksErrorDataHook.root} className={s.root}>
      <SectionNotification.Icon icon={<Error />} />
      <SectionNotification.Text>
        <div data-hook={DigitalLinksErrorDataHook.errorTitle}>{t('thankYou.DOWNLOAD_LINKS_ERROR')}</div>
        <div data-hook={DigitalLinksErrorDataHook.errorDescription}>{t('thankYou.DOWNLOAD_LINKS_ERROR_CONTINUED')}</div>
      </SectionNotification.Text>
    </SectionNotification>
  );
}
